import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { NotificationService } from '../notifications/notification.service';
import { ApiException } from '@shared/http-clients/http-clients';

/** Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
@Injectable()
export class AppErrorHandler extends ErrorHandler {
  constructor(private notificationsService: NotificationService) {
    super();
  }

  handleError(error: Error | HttpErrorResponse | ApiException) {
    if (error instanceof ApiException && error.message === 'Unauthorized') {
      // this should be only if refresh token is expired
    } else {
      let displayMessage = 'An error occurred.';

      if (!environment.production) {
        displayMessage += ' See console for details.';
      }

      this.notificationsService.error(displayMessage);
    }

    return super.handleError(error);
  }
}
