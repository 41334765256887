import { Injector } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { SessionService } from './session/session.service';

export function appInitializerFactory(injector: Injector) {
  return () => {
    return new Promise<boolean>((resolve, reject) => {
      const authService = injector.get(AuthService);

      authService.init().then(result => {
        if (result) {
          const sessionService = injector.get(SessionService);

          sessionService.fetchUserInfo().toPromise().then(
            () => resolve(true),
            () => {
              authService.logout();
              resolve(true);
            });
        } else {
          authService.logout();
          resolve(true);
        }
      }).catch(() => {
        authService.logout();
        resolve(true);
      });
    });
  };
}
