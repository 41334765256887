import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { finalize, tap, take, flatMap, filter, toArray } from 'rxjs/operators';
import { Announcement } from '@features/home/models/announcement.model';

@Injectable({
  providedIn: 'root'
})
export class CoreDataService {
  constructor() { }
}
