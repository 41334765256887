import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouterDataService {
  private _activatedRouteData$ = new BehaviorSubject<any>({
    title: '',
    showFooter: false,
    hideToolbar: true,
    hideSideNav: false
  }); // todo create a model for activated route data

  readonly activatedRouteData$ = this._activatedRouteData$.asObservable();

  setActivatedRouteData(data) {
    data.showFooter = data.showFooter ? true : false;
    data.hideToolbar = data.hideToolbar ? true : false;
    data.hideSideNav = data.hideSideNav ? true : false;
    this._activatedRouteData$.next(data);
  }
}
