import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy',
  pure: true
})
export class FilterByPipe implements PipeTransform {
  transform(items: any[], callback: (item: any, data: any) => boolean, args?: any): any {
    if (!items || !callback) {
      return items;
    }
    return items.filter(item => callback(item, args));
  }
}