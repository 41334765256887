import browser from 'browser-detect';
import { Component, OnInit, OnDestroy, Inject, ViewChild, HostListener } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { environment as env } from 'environments/environment';

import {
  routeAnimations,
  AppState,
  selectSettingsStickyHeader,
  selectSettingsLanguage,
  selectEffectiveTheme
} from '@core/core.module';
import {
  actionSettingsChangeAnimationsPageDisabled,
  actionSettingsChangeLanguage
} from '@core/settings/settings.actions';
import { AuthService } from '@core/auth/auth.service';
import { SessionService } from '@core/session/session.service';
import { UserInfoModel, API_BASE_URL } from '@shared/http-clients/http-clients';
import { map, switchMap, filter, distinctUntilChanged, tap } from 'rxjs/operators';
import { RouterOutlet, Router } from '@angular/router';
import { RouterDataService } from '@core/router/router-data.service';
import * as moment from 'moment-timezone';
import { Roles } from '@core/helpers/all-roles';
import { MenuItem } from '@core/models/menu-item.model';
import { MatDialog } from '@angular/material/dialog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'mypc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations]
})
export class AppComponent implements OnInit, OnDestroy {
  isProd = env.production;
  envName = env.envName;
  version = env.versions.app;
  year = new Date().getFullYear();
  logo = require('../../assets/logo-value.png');
  languages = [ 'da', 'en'];
  screenWidth: number;
  screenHeight: number;
  private screenWidth$ = new BehaviorSubject<number>(window.innerWidth);
  private screenHeight$ = new BehaviorSubject<number>(window.innerHeight);
  @ViewChild('sidenav') sidenav : MatSidenav;
  @HostListener('window:resize', ['$event'])
  onResize(event){
    this.screenWidth$.next(event.target.innerWidth);
    this.screenHeight$.next(event.target.innerHeight);
  };
  navigation: MenuItem[] = [
    { link: 'cases', label: 'mypc.menu.cases' },
    { link: 'consents', label: 'mypc.cases.consent-title' },
  ];
  navigationSideMenu = [
    { link: 'home', label: 'mypc.menu.account.information' },
    ...this.navigation
  ];
  baseUrl: string;

  isAuthenticated$: Observable<boolean>;
  userInfo$: Observable<UserInfoModel>;
  userProfilePicture$: Observable<string>;
  stickyHeader$: Observable<boolean>; // is this important for our case or the header will remain the same, maybe for mobile is different
  language$: Observable<string>;
  theme$: Observable<string>;
  shouldHideToolbar$: Observable<boolean>;
  shouldShowFooter$: Observable<boolean>;
  shouldHideSideNav$ : Observable<boolean>;
  routeTitle$: Observable<string>;
  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private userData: SessionService,
    private routerData: RouterDataService,
    private router: Router,
    public dialog: MatDialog,
    @Inject(API_BASE_URL) baseUrl?: string
  ) {
    this.baseUrl = baseUrl ? baseUrl : env.apiBaseUrl;
  }

  private static isIEorEdgeOrSafari() {
    return ['ie', 'edge', 'safari'].includes(browser().name);
  }

  ngOnInit(): void {
    moment.tz.setDefault('Europe/Copenhagen');
    if (AppComponent.isIEorEdgeOrSafari()) {
      this.store.dispatch(
        actionSettingsChangeAnimationsPageDisabled({
          pageAnimationsDisabled: true
        })
      );
    }

    this.isAuthenticated$ = this.authService.isAuthenticated$;
    this.userInfo$ = this.userData.userInfo$;


    this.dialog.afterOpened.pipe(
      distinctUntilChanged(),
      untilDestroyed(this),
      switchMap(() => this.isAuthenticated$),
      distinctUntilChanged(),
      filter(isAuthenticated => !isAuthenticated)
    ).subscribe(() => {
      this.dialog.closeAll();
      this.authService.logout();
      window.location.reload();
    })

    this.shouldHideToolbar$ = combineLatest([
      this.routerData.activatedRouteData$
    ]).pipe(
      map(([routeData]) => routeData && routeData.hideToolbar)
    );

    this.shouldHideSideNav$ = combineLatest(
      [this.routerData.activatedRouteData$]
    ).pipe(
      map(([routeData]) => routeData && routeData.hideSideNav)
    )
    this.shouldShowFooter$ = this.routerData.activatedRouteData$.pipe(
      map(data => data && data.showFooter)
    );
    this.routeTitle$ = this.routerData.activatedRouteData$.pipe(
      map(data => data && data.title)
    );

    this.stickyHeader$ = this.store.pipe(select(selectSettingsStickyHeader));
    this.language$ = this.store.pipe(select(selectSettingsLanguage));
    this.theme$ = this.store.pipe(select(selectEffectiveTheme));

    this.screenWidth$.subscribe(width => {
      this.screenWidth = width;
    });
    this.screenHeight$.subscribe(height => {
      this.screenHeight = height;
    });
  }

  onLogoutClick() {
    this.authService.logout();
    window.location.reload();
  }

  onLanguageSelect({ target: { value: language}  }) {
    this.store.dispatch(actionSettingsChangeLanguage({ language }));
  }

  onRouterOutletActivate(routerOutlet: RouterOutlet) {
    if (routerOutlet && routerOutlet.activatedRouteData) {
      this.routerData.setActivatedRouteData(routerOutlet.activatedRouteData);
    }
  }

  ngOnDestroy() { }
}
