import { Injectable } from '@angular/core';
import { CanActivate, Router, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { AuthService } from '@core/auth/auth.service';
import { SessionService } from '@core/session/session.service';
import { map, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ValidGuard implements CanActivate, CanActivateChild {
  constructor(
    private auth: AuthService,
    private session: SessionService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([
      this.session.userInfo$.pipe(distinctUntilChanged())
    ]).pipe(
      map(([userInfo]) => {
        if (userInfo) return true;

        this.auth.logout();
        this.router.navigate(['login']);
        
        return false;
      })
    )
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }

}
