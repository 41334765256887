import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading, PreloadAllModules } from '@angular/router';
import { ValidGuard } from '@core/guards/valid-guard';
import { AuthGuard } from '@core/core.module';
import { environment } from 'environments/environment';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, ValidGuard],
    loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'home',
    canActivate: [AuthGuard, ValidGuard],
    loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'account',
    canActivate: [AuthGuard, ValidGuard],
    loadChildren: () => import('./features/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'cases',
    canActivate: [AuthGuard, ValidGuard],
    loadChildren: () => import('./features/cases/cases.module').then(m => m.CasesModule)
  },
  {
    path: 'settings',
    canActivate: [AuthGuard, ValidGuard],
    loadChildren: () => import('./features/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'consents',
    canActivate: [AuthGuard, ValidGuard],
    loadChildren: () => import('./features/consents/consents.module').then(m => m.ConsentsModule)
  },
  {
    path: 'mitidcallback',
    loadChildren: () => import('./features/mitId/mitid.module').then(m => m.MitIdModule)
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: environment.production ? NoPreloading : PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
