import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, throwError, of } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError, tap, take, finalize, map } from 'rxjs/operators';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { USER_INFO_KEY, SessionService } from '../session/session.service';
import { TokenData, TokenResponse } from './auth.models';
import { API_BASE_URL } from '@shared/http-clients/http-clients';
import { NgxPermissionsService } from 'ngx-permissions';
import { Router } from '@angular/router';

export const AUTH_KEY = 'AUTH_DATA';
export const tokenEndpoint = '/api/Token';
export const mitIdEndpoint = '/api/v1/Login/OpenMitId';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _isAuthenticated$ = new BehaviorSubject<boolean>(false);
  readonly isAuthenticated$ = this._isAuthenticated$.asObservable();

  private _hasUserAcceptedTermsConditions$ = new BehaviorSubject<boolean>(true);
  readonly hasUserAcceptedTermsConditions$ = this._hasUserAcceptedTermsConditions$.asObservable();

  private _isLoadingLogin$ = new BehaviorSubject<boolean>(false);
  readonly isLoadingLogin$ = this._isLoadingLogin$.asObservable();

  private _tokenData$ = new BehaviorSubject<TokenData>(undefined);
  readonly tokenData$ = this._tokenData$
    .asObservable()
    .pipe(map(tokenData => (tokenData ? tokenData : this.getTokenData())));

  private _mitIdToken$ = new BehaviorSubject<any>(null);
  readonly mitIdToken$ = this._mitIdToken$.asObservable();

  get tokenData() {
    const tokenData = this._tokenData$.getValue();
    return tokenData ? tokenData : this.getTokenData();
  }

  get tokenUrl(): string {
    return (this.baseUrl + tokenEndpoint).replace(/[?&]$/, '');
  }

  get mitUrl(): string {
    return (this.baseUrl + mitIdEndpoint).replace(/[?&]$/, '');
  }

  constructor(
    private http: HttpClient,
    private storageService: LocalStorageService,
    private permissionService: NgxPermissionsService,
    private session: SessionService,
    private router: Router,
    @Inject(API_BASE_URL) private baseUrl: string
  ) {}

  init(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      const loginInfo = this.storageService.getTypedItem<TokenData>(AUTH_KEY);

      if (!loginInfo) {
        this.logout();
        resolve(false);
        return;
      }
      const utcNow = this.toUTCTimeStamp(new Date());
      const expires = new Date(loginInfo['.expires']);
      const utcExpires = this.toUTCTimeStamp(expires);

      if (utcNow > utcExpires) {
        this.refreshToken(loginInfo)
          .pipe(
            map(() => true),
            catchError(() => of(false))
          )
          .subscribe(result => resolve(result));
      } else {
        this._isAuthenticated$.next(true);
        resolve(true);
      }
    });
  }

  login(
    email: string,
    password: string,
    rememberMe: boolean
  ): Observable<TokenResponse> {
    this._isLoadingLogin$.next(true);

    const body = new HttpParams()
      .set('userName', email)
      .set('password', password)
      .set('grant_type', 'password')
      .set('client_id', 'PC-APP')
      .set('client_secret', 'PC-SECRET')
      .set('remember_me', String(rememberMe));

    // todo -> grant_type, client_id, client_secret should go to config

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
        .set('Access-Control-Allow-Origin', '*')
        .set('Access-Control-Allow-Headers', '*')
    };

    return this.http
      .post<TokenResponse>(this.tokenUrl, body.toString(), options)
      .pipe(
        finalize(() => this._isLoadingLogin$.next(false)),
        tap(token => {
          const loginInfo = token as TokenData;
          loginInfo.rememberMe = rememberMe;
          this.storageService.setItem(AUTH_KEY, loginInfo);
          this._tokenData$.next(loginInfo);
          this._isAuthenticated$.next(true);
        })
      );
  }

  openMitId() {
    this._isLoadingLogin$.next(true);
    return this.http
      .get(this.mitUrl)
      .pipe(finalize(() => this._isLoadingLogin$.next(false)));
  }

  authorizeMitIdUser(url: string) {
    var params =
      'grant_type=any&client_id=PC-APP&client_secret=PC-SECRET&url=' + url;
    var xhr = new XMLHttpRequest();
    xhr.open('POST', this.tokenUrl, true);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Access-Control-Allow-Headers', '*');
    xhr.setRequestHeader(
      'Content-Type',
      'application/x-www-form-urlencoded;charset=UTF-8'
    );

    xhr.onload = (e: any) => {
      if (xhr.response != null && xhr.response != '') {
        let token: any = JSON.parse(xhr.response);

        if (token.error) {
          this._mitIdToken$.next(token);
          this.router.navigate(['login']);
        } else {
          token.rememberMe = false;
          this.storageService.setItem(AUTH_KEY, token);
          this._tokenData$.next(token);
          this._isAuthenticated$.next(true);
          this.session.fetchUserInfo().subscribe(() => {
            this.router.navigate(['home']);
          });
        }
      }
    };

    xhr.send(params);
  }

  logout() {
    if (
      this.storageService.getItem(AUTH_KEY) ||
      this.storageService.getItem(USER_INFO_KEY)
    ) {
      this.storageService.clear();
    }

    this.permissionService.flushPermissions();
    this.session.clearData();
    this._isAuthenticated$.next(false);
    this._hasUserAcceptedTermsConditions$.next(false);
  }

  refreshToken(tokenData: TokenData): Observable<TokenResponse> {
    if (!tokenData) {
      this.logout();
      return throwError('Cannot refresh token without previous tokenData');
    }

    const body = new HttpParams()
      .set('refresh_token', tokenData.refresh_token)
      .set('grant_type', 'refresh_token')
      .set('client_id', 'PC-APP')
      .set('client_secret', 'PC-SECRET')
      .set('remember_me', String(tokenData.rememberMe));

    const options = {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded;charset=UTF-8'
      )
    };

    return this.http.post(this.tokenUrl, body.toString(), options).pipe(
      take(1),
      tap(
        (res: TokenResponse) => {
          const refreshedTokenData: TokenData = { ...tokenData, ...res };
          this.storageService.setItem(AUTH_KEY, refreshedTokenData);
          this._tokenData$.next(refreshedTokenData);
          this._isAuthenticated$.next(true);
        },
        () => this.logout()
      )
    );
  }

  setTermsConditions(status: boolean) {
    const loginInfo = this._tokenData$.getValue();
    this.storageService.setItem(AUTH_KEY, loginInfo);
    this._tokenData$.next(loginInfo);
    this._hasUserAcceptedTermsConditions$.next(status);
  }

  private getTokenData(): TokenData {
    const tokenData = this.storageService.getTypedItem<TokenData>(AUTH_KEY);
    this._tokenData$.next(tokenData);
    return tokenData;
  }

  // todo move this to helper method
  private toUTCTimeStamp(dateTime: Date): number {
    return Date.UTC(
      dateTime.getUTCFullYear(),
      dateTime.getUTCMonth(),
      dateTime.getUTCDate(),
      dateTime.getUTCHours(),
      dateTime.getUTCMinutes(),
      dateTime.getUTCSeconds(),
      dateTime.getUTCMilliseconds()
    );
  }

  setIsAuthenticated(isAuthenticated: boolean) {
    this._isAuthenticated$.next(isAuthenticated);
  }

  setTokenData(loginInfo: TokenData) {
    this._tokenData$.next(loginInfo);
  }
}

export interface ContextResponse {
  Error: string;
}
