import { Injectable } from '@angular/core';
import {
  UserInfoClient,
  UserInfoModel,
  ShortPermissionModel
} from '../../shared/http-clients/http-clients';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, finalize, tap, map } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';

export const USER_INFO_KEY = 'USER_INFO';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private _userInfo$ = new BehaviorSubject<UserInfoModel>(undefined);
  private _isLoadingUserInfo$ = new BehaviorSubject<boolean>(false);
  readonly currentUserId = this._userInfo$.pipe(
    map(userInfo => userInfo ? userInfo.id : undefined)
  )

  get userInfo$(): Observable<UserInfoModel> {
    return this._userInfo$.asObservable();
  }

  get isLoadingUserInfo$(): Observable<boolean> {
    return this._isLoadingUserInfo$.asObservable();
  }

  constructor(
    private userInfoClient: UserInfoClient,
    private permissionService: NgxPermissionsService
  ) {}

  fetchUserInfo() {
    this._isLoadingUserInfo$.next(true);

    return this.userInfoClient.get().pipe(
      take(1),
      finalize(() => this._isLoadingUserInfo$.next(false)),
      tap(userInfo => {
        this.loadRolesAndPermissions(userInfo);
        this._userInfo$.next(userInfo);
      })
    );
  }

  setUserInfo(userInfo: UserInfoModel) {
    this._userInfo$.next(userInfo);
  }

  clearData() {
    this._userInfo$.next(undefined);
  }

  // todo move to helper
  private isNullOrWhitespace(input: string): boolean {
    return !input || !input.trim();
  }

  private loadRolesAndPermissions(userInfo: UserInfoModel) {
    let permissions: Map<string, string[]> = new Map<string, string[]>();

    if (userInfo.userPermissions) {
      userInfo.userPermissions.forEach(permission => {
        permissions.set(
          permission.name,
          this.mapShortPermissionModelToNgxPermissions(permission)
        );
      });
    }

    let array: string[] = [];

    // if (userInfo.roles) {
    //   userInfo.roles.forEach(role => {
    //     array.push(`Roles.${role.code}`);

    //     if (role.permissions) {
    //       role.permissions.forEach(permission => {
    //         permissions.set(
    //           permission.name,
    //           this.mapShortPermissionModelToNgxPermissions(permission)
    //         );
    //       });
    //     }
    //   });
    // }

    for (let key of permissions.keys()) {
      array.push(...permissions.get(key));
    }

    this.permissionService.loadPermissions(array);
  }

  private mapShortPermissionModelToNgxPermissions(
    permission: ShortPermissionModel
  ): string[] {
    let permissions: string[] = [];

    if (permission.read) {
      permissions.push(`Permissions.${permission.name}.Read`);
    }

    if (permission.create) {
      permissions.push(`Permissions.${permission.name}.Create`);
    }

    if (permission.update) {
      permissions.push(`Permissions.${permission.name}.Update`);
    }

    if (permission.delete) {
      permissions.push(`Permissions.${permission.name}.Delete`);
    }

    return permissions;
  }
}
