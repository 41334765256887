import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[cachedInnerHtml]'
})
export class CachedInnerHtmlDirective {

  @Input()
  public get cachedInnerHtml(): string {
    return this.elRef.nativeElement.innerHTML;
  }
  public set cachedInnerHtml(html: string) {
    if(this.elRef.nativeElement.innerHTML !== html) {
      this.renderer.setProperty(this.elRef.nativeElement, 'innerHTML', html);
    }
  }
  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2
  ) { }

}
